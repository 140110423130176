import React from 'react';
import isValidLicence from '../shared/Utils';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Redirect} from 'react-router';
import {setExpDate} from '../redux/actions/dataActions';

const RouteGuard = ({component: Component, ...rest}) => {
  const dispatch = useDispatch();
  const licenceKey = useSelector(state => state.data.licenceKey);
  const isValid = licenceKey ? isValidLicence(licenceKey) : false;
  !isValid && dispatch(setExpDate(null))
  return (
    <Route {...rest} render={(props) => (
      isValid
        ? <Component {...props} />
        : <Redirect to='/' />
    )} />
  );
};

export default RouteGuard;