import configureStore from './reduxStore';
import reducer from './reducers';

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch {
    // ignore write errors
  }
};

const store = configureStore(reducer);

store.subscribe(()=>{
  saveState(store.getState());
})

export default store;