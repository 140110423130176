import { combineReducers } from 'redux';

import { azureReducer } from './azureReducer';
import { dataReducer } from './dataReducer';

const rootReducer = combineReducers({
  azure: azureReducer,
  data: dataReducer,
})

export default rootReducer;
