import {authProvider, getTokenRedirect} from './authProvider';
import moment from 'moment';
import store from './redux';

let graph = require('@microsoft/microsoft-graph-client');

async function getAuthenticatedClient () {
  const token = await getTokenRedirect();
  if(token?.accessToken) {
    store.dispatch({
      type: 'AAD_ACQUIRED_ACCESS_TOKEN_SUCCESS',
      payload: token.accessToken
    });
    store.dispatch({
      type: 'AAD_AUTHENTICATED_STATE_CHANGED',
      payload: 'Authenticated'
    });
  }
  // Initialize Graph client
  const client = graph.Client.init({
    // Use the provided access token to authenticate
    // requests
    authProvider: (done) => {
      done(null, token.accessToken);
    }
  });

  return client;
}

export async function getUserDetails () {
  const client = await getAuthenticatedClient();

  const user = await client.api('/me').get();
  return user;
}

export async function getRoomsWithCalendars () {
  const roomsWithCalendars = [];
  const client = await getAuthenticatedClient();
  const rooms = await client
    // .api(`/me/findRooms?RoomList=meeting@isdmsolutions.xyz`).version('beta') ////12 rooms and desks
    // .api(`/places/meeting@isdmsolutions.xyz/microsoft.graph.roomlist/rooms`) //4 only rooms
    .api(`/places/microsoft.graph.room`) //12 rooms and desks
    .get();
  await Promise.all(rooms.value.map(async room => {
    try {
      const calendar = await client.api(`/users/${room.emailAddress}/calendars`).get();
      roomsWithCalendars.push({
        room: room,
        calendar: calendar.value.filter(calendar => {
          return calendar.canEdit && calendar.canViewPrivateItems
        })
      });
    } catch (e) {
    }

  }));
  return roomsWithCalendars;
}

const day = moment().format('YYYY-MM-DD')
const endOfDay = moment().add(1, 'day').format('YYYY-MM-DD')

export async function getCalendarEvents (roomEmail, calendarId) {
  const startUrl = roomEmail ? `users/${roomEmail}` : 'me';
  const client = await getAuthenticatedClient();
  const events = await client.api(`${startUrl}/calendars/${calendarId}/calendarView?startDateTime=${day}&endDateTime=${endOfDay}&$select=subject,start,end,organizer,sensitivity&$top=1000`).get();
  localStorage.setItem('CalendarData', JSON.stringify(events))
  events.value = events.value.map(event => {
    event.start.dateTime = moment.utc(event.start.dateTime).local().format()
    event.end.dateTime = moment.utc(event.end.dateTime).local().format()
    return event
  })
  return events;
}

// @GET("me/calendars/{calendarId}/calendarView")



export async function bookCalendarEvent (roomEmail, calendarId, event) {
  const startUrl = roomEmail ? `users/${roomEmail}` : 'me';

  const client = await getAuthenticatedClient();
  const resp = await client.api(`${startUrl}/calendars/${calendarId}/events`).post(event);
  // console.log(resp);
  return resp;
}

export async function getRoomList () {
  const client = await getAuthenticatedClient();
  const roomList = await client.api('/places/microsoft.graph.roomlist').get();
  return roomList;
}

export async function getCalendars () {
  const client = await getAuthenticatedClient();
  const roomList = await client.api('/me/calendars').get();
  return roomList;
}
