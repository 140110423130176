import React from 'react';


const Loader = (props) => {
  return (
    <div className='loader-main-block'>
      <style dangerouslySetInnerHTML={{
        __html: `
      .lds-spinner div:after { background: ${props.color || 'white'} }
    `
      }}/>
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default Loader;