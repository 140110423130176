import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import WifiOff from '@material-ui/icons/WifiOff';
import HighlightOff from '@material-ui/icons/HighlightOff';
import Search from '@material-ui/icons/Search';
import {Uma} from '../IconSet';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import {getRoomsWithCalendars, getCalendars} from '../GraphService';
import RoomNameModal from '../components/RoomNameModal'
import {setCalendarParam} from '../redux/actions/dataActions';
// import {signOut} from '../authProvider';

// import Switch from '../shared/Switch';
import Loader from './Loader';

export const RoomSelect = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const account = useSelector(state => state.azure.account);
  const [searchText, setSearchText] = React.useState('');
  const [checkedId, setCheckedId] = React.useState(false);
  // const [value, setValue] = React.useState(false);
  const [initialData, setInitialData] = React.useState([]);
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedRoomEmailAddress, setSelectedRoomEmailAddress] = React.useState('');
  const [selectedRoomName, setSelectedRoomName] = React.useState('');
  const [online, setOnline] = React.useState(navigator.onLine);
  // const accountName = JSON.parse(localStorage.getItem('accountName'));

  function isOnline () {
    window.addEventListener('online', (e) => setOnline(navigator.onLine))
    window.addEventListener('offline', (e) => setOnline(navigator.onLine))
  }

  useEffect(() => {
    isOnline()
  }, [online])

  React.useEffect(() => {
    // checkedId && bookCalendarEvent(selectedRoomEmailAddress, checkedId);

    // checkedId && getCalendarEvents(selectedRoomEmailAddress, checkedId);
    setSelectedRoomName(initialData.find(item => {

      return item?.room?.emailAddress === selectedRoomEmailAddress;
    })?.room?.displayName);
    // bookCalendarEvent(selectedRoomEmailAddress, checkedId);
  }, [checkedId]);

  const onChange = (e, calendar, room) => {
    !!room && setSelectedRoomEmailAddress(room.room.emailAddress);
    setCheckedId((!checkedId || checkedId !== calendar.id) ? calendar.id : false);

  }

  React.useEffect(() => {
    initialData.map(el => {
      if (el.room.emailAddress === selectedRoomEmailAddress) {
        localStorage.setItem('currentCapacity', el.room.capacity)
      }
    })
  }, [selectedRoomEmailAddress])

  const handleSubmit = (e) => {
    e.preventDefault();
    const roomName = JSON.parse(localStorage.getItem('accountName'))
    if (roomName === 'mailbox') {
      const selectedRoom = initialData.find((el) => {
        return el.id === checkedId
      })
      setSelectedRoomName(selectedRoom?.name);
    }
    setIsOpenModal(true);
  }

  const handleSubmitRoomName = (roomName) => {
    setIsOpenModal(false);
    dispatch(setCalendarParam({selectedRoomEmailAddress, checkedId}));
    localStorage.setItem('selectedRoomEmailAddress', JSON.stringify(selectedRoomEmailAddress));
    localStorage.setItem('checkedId', JSON.stringify(checkedId));
    history.push('/pin');
  }
  const handleClose = () => {
    setIsOpenModal(false);
  }

  React.useEffect(() => {
    async function fetchData () {
      setIsLoading(true);
      const accountName = JSON.parse(localStorage.getItem('accountName'))
      let data;
      try {
        if (accountName === 'mailbox') {
          data = await getCalendars();
          setIsLoading(false);
          setInitialData(data.value.filter(el => el.canEdit))
        } else {
          data = await getRoomsWithCalendars();
          setIsLoading(false);
          setInitialData(data);
        }
      } catch {
        setIsLoading(false);
      }
    }

    account && fetchData();
  }, [account]);

  return (
    <div className="room-select-container">
      <RoomNameModal open={isOpenModal} roomName={selectedRoomName} handleClose={handleClose}
                     handleSubmitRoomName={handleSubmitRoomName}></RoomNameModal>
      <div className="icons-row">
        {online ? <div></div> : <WifiOff/>}
        <HighlightOff onClick={() => {
          dispatch({type: 'AAD_LOGIN_SUCCESS', payload: ''});
          history.push('/acctype');
        }}/>
      </div>
      <form onSubmit={handleSubmit} className="account-type">
        <Uma fill={'#6633cc'} height={30}/>
        {/*<Typography variant="h6" gutterBottom>*/}
        {/*    MEETING ROOM*/}
        {/*</Typography>*/}
        <label className="rooms__label"
               htmlFor="licenseKey">{'Select the meeting room to display on this tablet'}</label>
        <InputBase
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className="search-input"
          placeholder="Search meeting rooms"
          startAdornment={
            <InputAdornment position="start">
              <Search/>
            </InputAdornment>
          }/>
        <div className="items-list">
          {
            isLoading ? <div className='auth-loader'><Loader color="#3e2e83"/></div> : (
              JSON.parse(localStorage.getItem('accountName')) === 'delegate' ? (
                initialData
                  .map((room, i) => {
                    return room.calendar
                      .map((calendar, j) => {
                        if (calendar.name.toLowerCase().includes(searchText.toLowerCase()) || room.room.displayName.toLowerCase().includes(searchText.toLowerCase()))
                          return (
                            <label key={i + j} className="items-list__item" htmlFor={calendar.id}>
                              <div className="items-list__item__label">
                                <p>{room.room.displayName} - {calendar.name}</p>
                              </div>
                              <div className="container">
                                <div className="round">
                                  <input type="checkbox"
                                         id={calendar.id}
                                         checked={checkedId === calendar.id}
                                         readOnly={true}
                                         onClick={e => onChange(e, calendar, room)}/>
                                  <label htmlFor={calendar.id}></label>
                                </div>
                              </div>
                            </label>
                          )
                      })
                  })
              ) : (
                initialData
                  .filter(e => {
                    return e.name?.toLowerCase().includes(searchText.toLowerCase());
                  })
                  .map((item, i) => {
                    return (
                      <label key={i} className="items-list__item" htmlFor={item.id}>
                        <div className="items-list__item__label">
                          <p>{item.name}</p>
                          {/*<span>{item.emailAddress}</span>*/}
                        </div>
                        <div className="container">
                          <div className="round">
                            <input type="checkbox"
                                   id={item.id}
                                   checked={checkedId === item.id}
                                   readOnly={true}
                                   onClick={e => onChange(e, item)}/>
                            <label htmlFor={item.id}></label>
                          </div>
                        </div>
                      </label>
                    )
                  }))
            )
          }
        </div>


        {/*<p className="switch-name">Appearance:</p>*/}
        {/*<div className="switcher-container">*/}
        {/*    <label>Light</label>*/}
        {/*    <Switch*/}
        {/*        isOn={value}*/}
        {/*        handleToggle={() => setValue(!value)}*/}
        {/*    />*/}
        {/*    <label>Dark</label>*/}
        {/*</div>*/}
        <button disabled={!checkedId} className="submit-button" type="submit">SELECT</button>
      </form>
    </div>
  );
}
