import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import WifiOff from '@material-ui/icons/WifiOff';
import HighlightOff from '@material-ui/icons/HighlightOff';
import Search from '@material-ui/icons/Search';
import {Uma} from '../IconSet';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import {getRoomList, getCalendars} from '../GraphService';
import {signOut} from '../authProvider';

import Switch from '../shared/Switch';
import Loader from './Loader';

export const RoomList = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = React.useState('');
  const [checkedId, setCheckedId] = React.useState(false);
  const [value, setValue] = React.useState(false);
  const [initialData, setInitialData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [online, setOnline] = React.useState(navigator.onLine)

  function isOnline () {
    window.addEventListener('online', (e) => setOnline(navigator.onLine))
    window.addEventListener('offline', (e) => setOnline(navigator.onLine))
  }

  useEffect(() => {
    isOnline()
  }, [online])
  const onChange = (e, item) => {
    setCheckedId((!checkedId || checkedId !== item.id) ? item.id : false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push('/room-select');
  }

  React.useEffect(() => {
    async function fetchData () {
      const response = await getRoomList();
      setIsLoading(false);
      setInitialData(response?.value);
    }

    try {
      setIsLoading(true);
      fetchData();
    } catch {
      setIsLoading(false);
    }
  }, []);

  return (
    <div className="rooms-container">
      <div className="icons-row">
        {online ? <div></div> : <WifiOff/>}
        <HighlightOff onClick={() => {
          dispatch({type: 'AAD_LOGIN_SUCCESS', payload: ''});
          history.push('/acctype');
        }}/>
      </div>
      <form onSubmit={handleSubmit} className="account-type">
        <Uma fill={'#6633cc'} height={30}/>
        <label className="rooms__label"
               htmlFor="licenseKey">{'Select the room list'}</label>
        <InputBase
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className="search-input"
          placeholder="Enter room list name or email address"
          startAdornment={
            <InputAdornment position="start">
              <Search/>
            </InputAdornment>
          }/>
        <div className="items-list">
          {
            isLoading ? <div className='auth-loader'><Loader color="#3e2e83"/></div> : (
              initialData
                .filter(e => {
                  return e.displayName?.toLowerCase().includes(searchText.toLowerCase()) || e.emailAddress?.toLowerCase().includes(searchText.toLowerCase());
                })
                .map((item, i) => {
                  return (

                    <label key={i} className="items-list__item" htmlFor={item.id}>
                      <div className="items-list__item__label">
                        <p>{item.displayName}</p>
                        <span>{item.emailAddress}</span>
                      </div>
                      <div className="container">
                        <div className="round">
                          <input type="checkbox"
                                 id={item.id}
                                 checked={checkedId === item.id}
                                 readOnly={true}
                                 onClick={e => onChange(e, item)}/>
                          <label htmlFor={item.id}></label>
                        </div>
                      </div>
                    </label>
                  )
                })
            )
          }
        </div>
        {/*<p className="switch-name">Appearance:</p>*/}
        {/*<div className="switcher-container">*/}
        {/*  <label>Light</label>*/}
        {/*  <Switch*/}
        {/*    isOn={value}*/}
        {/*    handleToggle={() => setValue(!value)}*/}
        {/*  />*/}
        {/*  <label>Dark</label>*/}
        {/*</div>*/}
        <button disabled={!checkedId} className="submit-button" type="submit">SELECT</button>
      </form>
    </div>
  );
}
