import React, {useEffect, useState} from 'react';


export default function Time() {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const time = setInterval(() => {
            setTime(new Date())
        }, 1000);
        return () => clearInterval(time)
    }, [])
    return (
        <>{time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</>
    )
}