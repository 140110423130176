import React, {useState} from 'react';
import styles from './mainBlock.module.css';
import Dialog from '@material-ui/core/Dialog';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import moment from 'moment';

function RoomPagePopup(props) {
  const {open, handleClose, featureMeetings, currentMeeting, showEvent} = props;
  const [newEvents, setNewEvents] = useState(null);
  const [bookCalderBtn, setBookCalderBtn] = useState(true);

  function timeRounding() {
    const now = moment();
    if (now.minutes() <= 15) {
      now.minutes(0);
    } else if (now.minutes() <= 45) {
      now.minutes(30);
    } else if (now.minutes() <= 59) {
      now.add(1, 'hours').minutes(0)
    }
    return now.clone();
  }

  function showEventHandle() {
    setBookCalderBtn(true)
    showEvent(newEvents)
  }

  function getFirstThreeSlots() {
    let closestMeetings = [];
    if (featureMeetings) {
      [...currentMeeting, ...featureMeetings].filter(el => {
        if (moment(el.start.dateTime) < timeRounding().clone().add(3, "hours").add(30, 'minutes')) {
          closestMeetings.push(el);
        }
      })
    }
    return closestMeetings
  }

  function closePopup() {
    setNewEvents(null);
    handleClose();
    setBookCalderBtn(true);
  }

  function createSlots() {
    const slots = [];
    const now = timeRounding();

    for (let i = 0; i < 6; i++) {
      const time = now.clone().add(30 * i, 'minutes');
      slots.push(time);
    }
    return slots;
  }

  function availableTime() {
    const slots = createSlots()
    const closestMeetings = getFirstThreeSlots()
    let filteredSlots
    if (closestMeetings.length === 0) {
      return slots;
    } else {
      filteredSlots = slots.filter(slot => {
        return !closestMeetings.some(meeting => {
          let startTime = moment(meeting.start.dateTime);
          let endTime = moment(meeting.end.dateTime);
          let slotTime = slot;
          return slotTime.isBetween(startTime, endTime) || slotTime.clone().add(29, 'minutes').isBetween(startTime, endTime)
        })
      })
    }
    return filteredSlots;
  }

  availableTime();

  function showSlots(filtered) {
    let slotsTime = [];
    for (let i = 0; i < filtered.length; i++) {
      slotsTime.push({id: i, start: filtered[i], end: moment(filtered[i]).add(30, 'minutes')});
    }
    return slotsTime;
  }

  showSlots(availableTime());

  function createEvent(event) {
    setBookCalderBtn(false);
    const eventId = +event.target.id;
    if (event.target.checked) {
      const slots = showSlots(availableTime())
      slots.filter(el => {
        if (el.id === eventId) {
          setNewEvents(
              {
                'End': {
                  'DateTime': el.end.utc().format(),
                  "TimeZone": 'UTC'
                },
                'Start': {
                  'DateTime': el.start.utc().format(),
                  "TimeZone": 'UTC'
                },
                'Subject': "Meeting Room booked by UMA"
              }
          )
        }

      })
    }
  }

  return (
      <Dialog
          open={open}
          onClose={closePopup}
      >
        <div className={styles.popupContent}>
          <HighlightOffIcon fontSize='large' className={styles.closePopup} onClick={closePopup}/>
          <h2>BOOK CALENDAR</h2>
          <h5>Select the best meeting time</h5>
          <div className="items-list item-list-popup">
            {showSlots(availableTime()).length !== 0 ? showSlots(availableTime()).map((el, index) => {
              return (
                  <label className="items-list__item" key={index} style={index >= 3 ? {display: 'none'} : null}
                         htmlFor={index}>
                    <div className="items-list__item__label">
                      {el.start.toDate().toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit'
                      })} - {el.end.toDate().toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}
                    </div>
                    <div className="container">
                      <div className="round">
                        <input type="radio"
                               id={index}
                               name='chooseSlot'
                               onChange={event => createEvent(event)}
                        />
                        <label htmlFor={index}></label>
                      </div>
                    </div>
                  </label>
              )
            }) : <div className='items-list item-list-popup' style={{justifyContent: 'center'}}><h2>No time slots
              available</h2>
            </div>}
          </div>
          <button
              disabled={bookCalderBtn}
              onClick={() => showEventHandle(newEvents)}
              className={styles.popupSelectBtn}

          >SELECT
          </button>
        </div>
      </Dialog>
  )
}

export default RoomPagePopup