import {
  AAD_INITIALIZING,
  AAD_INITIALIZED,
  AAD_LOGIN_SUCCESS,
  AAD_LOGIN_FAILED,
  AAD_LOGIN_ERROR,
  AAD_CLEAR_ERROR,
  AAD_LOGOUT_SUCCESS,
  AAD_ACQUIRED_ID_TOKEN_SUCCESS,
  AAD_ACQUIRED_ID_TOKEN_ERROR,
  AAD_ACQUIRED_ACCESS_TOKEN_SUCCESS,
  AAD_ACQUIRED_ACCESS_TOKEN_ERROR,
  AAD_AUTHENTICATED_STATE_CHANGED,
  Unauthenticated,
  InProgress,
  Authenticated
} from '../../constants/azureActions';


const initialState = {
  initializing: false,
  initialized: false,
  idToken: null,
  accessToken: null,
  state: 'Unauthenticated',
};

export const azureReducer = (state = initialState, action) => {
  switch (action.type) {
    case AAD_INITIALIZING:
      return {
        ...state,
        initializing: true,
        initialized: false,
      };
    case AAD_INITIALIZED:
      return {
        ...state,
        initializing: false,
        initialized: true,
      };
    case AAD_ACQUIRED_ID_TOKEN_SUCCESS:
      return {
        ...state,
        idToken: action.payload,
      };
    case AAD_ACQUIRED_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        accessToken: action.payload,
      };
    case AAD_ACQUIRED_ACCESS_TOKEN_ERROR:
      return {
        ...state,
        accessToken: null,
      };
    case AAD_LOGIN_SUCCESS:
      return {
        ...state,
        account: action.payload,
      };
    case AAD_LOGIN_ERROR:
      return {
        ...state,
        state: action.payload,
      };
    case AAD_ACQUIRED_ID_TOKEN_ERROR:
    case AAD_LOGOUT_SUCCESS:
      return {...state, idToken: null, accessToken: null, account: null, state: null};
    case AAD_AUTHENTICATED_STATE_CHANGED:
      return {
        ...state,
        state: action.payload,
      };
    default:
      return state;
  }
};
