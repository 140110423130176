import React, {useEffect, useState} from 'react';
import styles from './mainBlock.module.css'
import temp from '../assets/img/icons-8-temperature_2020-08-31/icons-8-temperature.png'
import {Uma} from '../IconSet'
import {useSelector} from 'react-redux';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import {bookCalendarEvent, getCalendarEvents} from '../GraphService'
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {setPrevPath} from '../redux/actions/dataActions';
import moment from 'moment';
import RoomPagePopup from './RoomPagePopup';
import wave from '../assets/img/ic_heating.svg';
import Loader from "./Loader";
import Time from './Time';


function RoomPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [roomName, setRoomName] = useState(useSelector(state => state.data.roomName || JSON.parse(localStorage.getItem('roomName'))));
  const checkedId = useSelector(state => state.data.calendarParam.checkedId || JSON.parse(localStorage.getItem('checkedId')));
  // const calendarData = useSelector(state => state.data.calendarParam);
  const selectedRoomEmailAddress = useSelector(state => state.data.calendarParam.selectedRoomEmailAddress || JSON.parse(localStorage.getItem('selectedRoomEmailAddress')));
  const [calendar, setCalendar] = useState();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const {currentMeeting, featureMeetings} = getCurrentMeetings();
  const currentCapacity = localStorage.getItem('currentCapacity');

  function getTitleFontSize(title) {
    const resizeH1 = title.length;
    if (resizeH1 > 256) {
      return '1.8vw'
    }
    if (resizeH1 > 236 && resizeH1 <= 256) {
      return '1.8vw';
    }
    if (resizeH1 > 216 && resizeH1 <= 236) {
      return '2.2vw';
    }
    if (resizeH1 > 190 && resizeH1 <= 216) {
      return '2.3vw';
    }
    if (resizeH1 > 170 && resizeH1 <= 190) {
      return '2.4vw';
    }
    if (resizeH1 > 150 && resizeH1 <= 170) {
      return '2.5vw';
    }
    if (resizeH1 > 130 && resizeH1 <= 150) {
      return '2.6vw';
    }
    if (resizeH1 > 110 && resizeH1 <= 130) {
      return '2.7vw';
    }
    if (resizeH1 > 90 && resizeH1 <= 110) {
      return '2.8vw';
    }
    if (resizeH1 > 70 && resizeH1 <= 90) {
      return '3vw';
    }
    if (resizeH1 > 50 && resizeH1 <= 70) {
      return '3.2vw';
    }
    if (resizeH1 > 40 && resizeH1 <= 50) {
      return '3.4vw';
    }
    if (resizeH1 > 30 && resizeH1 <= 40) {
      return '3.5vw';
    }
    if (resizeH1 > 25 && resizeH1 <= 30) {
      return '4vw';
    }
    if (resizeH1 <= 25) {
      return '4.5vw';
    }
  }

  useEffect(() => {
    setInterval(function () {
      fetchData();
    }, 60000);
  }, [])

  function openPopup() {
    setIsOpenPopup(true);
  }

  function handleClose(event) {
    setIsOpenPopup(false);
  }

  async function fetchData() {
    const data = await getCalendarEvents(selectedRoomEmailAddress, checkedId);
    if (data) {
      setCalendar(data);
    }
  }

  useEffect(() => {
    dispatch(setPrevPath(history.location.pathname));
    fetchData();
  }, [])
  const goBackHandle = () => {
    history.push('/pin');
  }

  function getCurrentMeetings() {
    let currentMeeting = []
    let featureMeetings = []
    if (calendar) {
      calendar.value.map(el => {
        if (moment(el.start.dateTime) < moment() && moment(el.end.dateTime) > moment()) {
          currentMeeting.push(el)
        } else if (moment() < moment(el.start.dateTime)) {
          featureMeetings.push(el)
        }
      })
    }
    return {
      currentMeeting,
      featureMeetings
    }
  }

  async function createEvent(newEvent) {

    handleClose()
    const emailAdress = JSON.parse(localStorage.getItem('selectedRoomEmailAddress'))
    try {
      const res = await bookCalendarEvent(emailAdress, checkedId, newEvent);
      // const a  = await getCalendarEvents(selectedRoomEmailAddress, checkedId, newEvent)
      await fetchData()
    } catch (e) {
      // console.log(e)
    }
  }

  return (
      <div className='roomPage-container'>
        <div className={styles.mainBlock}>
          <div className={styles.leftSide}>
            <div className={styles.roomAvailable}>
              {/*// insert here*/}
              <div className={styles.leftFirst}>
                <div className={styles.dateBlock}>
                  <h5>Meeting Room</h5>
                  <h2>{roomName}</h2>
                </div>
                <div className={styles.title}>
                  {calendar ? (
                      <div className={styles.roomAvailableTitle}>
                        {currentMeeting.length > 0 ? currentMeeting.map(el => {
                          const text = el.subject
                          return (
                              <div key={el.id}>
                                <div>
                                  <h1
                                      style={{fontSize: getTitleFontSize(text)}}
                                  > {text}</h1>
                                </div>
                              </div>
                          )
                        }) : <h1>ROOM AVAILABLE</h1>}
                      </div>
                  ) : <Loader color="#3e2e83"/>}
                </div>
              </div>
              <div className={styles.leftSecond}>
                {calendar ? (
                    <>
                      {currentMeeting.length > 0 ? (
                          currentMeeting.map(el => {
                            return (
                                <div className={styles.bookNowBlock} key={el.id}>
                                  <div className={styles.bookingInProgress}>
                                    <button className={styles.bookingInProgressBtn}>MEETING IN PROGRESS
                                    </button>
                                    <span>Ending at {moment(el.end.dateTime).toDate().toLocaleTimeString([], {
                                      hour: '2-digit',
                                      minute: '2-digit'
                                    })}</span>

                                  </div>
                                  <div className={styles.endMeeting}>
                                    <p className={styles.bookedBy}>BOOKED BY</p>
                                    <span
                                        >{el.organizer.emailAddress.name}</span>
                                  </div>
                                </div>
                            )
                          })
                      ) : (
                          <div className={styles.bookNowBlock}>
                            <div className={styles.endMeeting}>
                              <button className={styles.bookNowBtn} onClick={() => openPopup()}>BOOK
                                NOW
                              </button>
                              {featureMeetings[0] ?
                                  <span>till {moment(featureMeetings[0].start.dateTime).toDate().toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                  })}</span> :
                                  <span>till end of day</span>}
                            </div>
                            <div className={styles.endMeeting}>
                            </div>
                          </div>
                      )}
                    </>
                ) : null}
              </div>
            </div>

            <div className={styles.meetings}>
              <div>
                <Uma fill={'#6633cc'} height={30}/>
              </div>

              <div className={styles.upcomingMeetings}>
                <p>UPCOMING MEETINGS</p>
              </div>
              <div className={styles.currentMeetingsBlock}>
                <ul className={styles.itemListUl}>
                  {calendar ? featureMeetings.map((el, idx) => {
                    return (
                        <li
                            className={idx === 0 ? styles.spanActive : styles.spanNotActive}
                            // style={idx >= 3 ? {display: 'none'}: null}
                            key={el.id}
                        >
                          <span
                              className={styles.elipsis}>{el.subject} {moment(el.start.dateTime).toDate().toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit'
                          })}
                            <span> {el.organizer.emailAddress.name}</span>
                          </span>

                        </li>
                    )
                  }) : null}
                </ul>
              </div>

            </div>
          </div>
          <div className={styles.capacity}>
            <div className={styles.timeRoom}>
              <div className={styles.meetingRoomLock}>
                <span>{moment().format("dddd")}</span>
                {/*<Time/>*/}
                <LockOutlinedIcon
                    style={{color: 'rgba(171, 146, 207, 1 )', fontSize: '1.5vw', cursor: 'pointer'}}
                    onClick={goBackHandle}/>
              </div>
              <span className={styles.month}>{moment().format('MMMM Do')}</span>
              <div className={styles.time}>
                <span>
                  {/*{moment().format('LT')}*/}
                  <Time/>
                </span>
              </div>
            </div>

            <div className={styles.temperature}>
              <div className={styles.meetingRoomSecondBlock}>
                <div className={styles.meetingRoomSecondBlockTemp}>
                  <div>
                    <img src={temp} alt="/"/>
                  </div>
                  <span>N/A</span>

                </div>
                <div className={styles.meetingRoomSecondBlockWave}>
                  <div>
                    <img src={wave} alt="/"
                         className={styles.wave}
                    />
                  </div>
                  <span>N/A</span>
                </div>
              </div>

              <div className={styles.maxPeopleBlock}>
                <PeopleAltOutlinedIcon style={{color: 'rgba(171, 146, 207, 1 )', fontSize: '2.7vw'}}/>
                <div className={styles.maxPeopleNum}>{currentCapacity ? currentCapacity : 'N/A'}</div>
                <div className={styles.maxPeopleText}>Maximum Capacity</div>
              </div>

            </div>

            <div className={styles.reserve}>
              <span>BOOK AVAILABLE TIMES</span>
              <button className={styles.reserveBtn} onClick={() => openPopup()}>RESERVE</button>
            </div>
          </div>
        </div>
        <RoomPagePopup
            featureMeetings={featureMeetings}
            currentMeeting={currentMeeting}
            open={isOpenPopup}
            handleClose={handleClose}
            showEvent={createEvent}
        />
      </div>
  );
}

export default RoomPage;
