import React, {useEffect} from 'react';
import WifiOff from '@material-ui/icons/WifiOff';
import HighlightOff from '@material-ui/icons/HighlightOff';
import {Uma} from '../IconSet';
import Backspace from '@material-ui/icons/Backspace';
import {useHistory} from 'react-router';
import {setPin} from '../redux/actions/dataActions';
import {useDispatch, useSelector} from 'react-redux';
// import {signOut} from '../authProvider';
//import {authProvider} from '../authProvider';
//import { ExchangeService, ExchangeVersion, WebCredentials, OAuthCredentials, Uri, DateTime, CalendarView, WellKnownFolderName, EwsLogging } from "ews-javascript-api";
// let graph = require('@microsoft/microsoft-graph-client');

export const Pin = () => {
  const dispatch = useDispatch();

  const [pinData, setPinData] = React.useState([]);
  const [firstEnteredPin, setFirstEnteredPin] = React.useState([]);
  const [error, setError] = React.useState('');

  const confirmationPin = useSelector(state => state.data.pin);

  const history = useHistory();
  const prevPath = useSelector(state => state.data.prevPath);
  // const state = useSelector(state => state)
  const [online, setOnline] = React.useState(navigator.onLine);

  function isOnline () {
    window.addEventListener('online', (e) => setOnline(navigator.onLine))
    window.addEventListener('offline', (e) => setOnline(navigator.onLine))
  }

  useEffect(() => {
    isOnline()
  }, [online])
  // React.useEffect(() => {
  //   async function anyNameFunction() {
  //     const token = await authProvider.getAccessToken();
  //     console.log(token);
  //     // const client = getAuthenticatedClient(token.accessToken);
  //     //
  //     // const user = await client.api('/me').get();
  //     // const roomsList = await client.api('/places/microsoft.graph.roomlist').get();
  //     // console.log(roomsList);
  //     // console.log(user);
  //     // const calendars = await client
  //     //   .api('/me/calendars')
  //     //   .get();
  //     // console.log(calendars);
  //     // let { ExchangeService, Uri, OAuthCredentials, ExchangeVersion, Folder, WellKnownFolderName, EwsLogging } = require("ews-js-api-browser");
  //     // EwsLogging.DebugLogEnabled = false;
  //     // let exch = new ExchangeService(ExchangeVersion.Exchange2013_SP1);
  //     // exch.Url = new Uri("https://outlook.office365.com/ews/exchange.asmx");
  //     // exch.Credentials = new OAuthCredentials(token.accessToken);
  //     // let rooms = await exch.GetRoomLists();
  //     // console.log(rooms);
  //
  //     // var ews = require("ews-javascript-api");
  //     // ews.EwsLogging.DebugLogEnabled = false;
  //     // var exch = new ews.ExchangeService(ews.ExchangeVersion.Exchange2013);
  //     // exch.HttpHeaders = { "X-AnchorMailbox": "delegated@isdmsolutions.xyz" };
  //     // exch.Credentials = new ews.OAuthCredentials(token.accessToken);
  //     // exch.Url = new ews.Uri("https://ews");
  //     // exch.ImpersonatedUserId = new ews.ImpersonatedUserId(ews.ConnectingIdType.SmtpAddress, "delegated@isdmsolutions.xyz");
  //     // console.log(exch);
  //     // let rooms = await exch.GetRoomLists();
  //     // console.log(rooms);
  //     // exch.HttpHeaders = { "X-AnchorMailbox": "delegated@isdmsolutions.xyz" };
  //     // var rule = new ews.Rule();
  //     // rule.DisplayName = "MoveInterestingToJunk";
  //     // rule.Priority = 1;
  //     // rule.IsEnabled = true;
  //     // rule.Conditions.ContainsSubjectStrings.Add("Interesting");
  //     // rule.Actions.MoveToFolder = new ews.FolderId(ews.WellKnownFolderName.JunkEmail);
  //     // var ruleop = new ews.CreateRuleOperation(rule);
  //     // exch.UpdateInboxRules([ruleop], true)
  //     //   .then(function (response) {
  //     //     console.log("success - update-inboxrules");
  //     //     ews.EwsLogging.Log(response, true, true);
  //     //   }, function (err) {
  //     //     debugger;
  //     //     console.log("error in update-inboxrules");
  //     //     ews.EwsLogging.Log(err, true, true);
  //     //   });
  //
  //     // return fetch("https://graph.microsoft.com/v1.0/", {
  //     //   method: 'GET',
  //     //   headers: {
  //     //     Authorization: 'Bearer ' + token.accessToken,
  //     //     'Content-Type': 'application/json',
  //     //   },
  //     // });
  //     //import credentials = require("./credentials"); //for username and password
  //     EwsLogging.DebugLogEnabled = false;
  //
  //     var service = new ExchangeService(ExchangeVersion.Exchange2010);
  //     service.Credentials = new OAuthCredentials(token.accessToken);
  //     service.Url = new Uri("https://outlook.office365.com/Ews/Exchange.asmx");
  //
  //     var view = new CalendarView(DateTime.Now.Add(-1, "week"), DateTime.Now); // appointments in last one week.
  //     service.FindAppointments(WellKnownFolderName.Calendar, view).then((response) => {
  //       let appointments = response.Items;
  //       let appointment = appointments[0];
  //       console.log("Subject: " + appointment.Subject);
  //       console.log("Start Time: " + appointment.Start);
  //       console.log("End Time: " + appointment.End);
  //       console.log("Recipients: ");
  //       appointment.RequiredAttendees.Items.forEach((a) => {
  //         console.log(a.Address);
  //       });
  //       console.log("unique id: " + appointment.Id.UniqueId, true, true);
  //
  //     }, function (error) {
  //       console.log(error)
  //     })
  //   }
  //   anyNameFunction();
  // });

  const pinFromStorage = confirmationPin || JSON.parse(localStorage.getItem('confirmationPin'))
  useEffect(() => {
    if (pinData.length === 4 && !firstEnteredPin.length && prevPath !== '/room-page') {
      setFirstEnteredPin(pinData)
      setError('');
      dispatch(setPin([...pinData]))
      localStorage.setItem('confirmationPin', JSON.stringify(pinData))
      setPinData([])
    }
    if (pinData.length === 4 && firstEnteredPin.length === 4 && prevPath !== '/room-page') {
      const pin = firstEnteredPin.join(',');
      const confirm = pinData.join(',');
      // console.log(pin, confirm, pin === confirm);
      if (pin !== confirm) {
        setError('PIN DO NOT MATCH');
        setFirstEnteredPin([])
        setPinData([])
      } else {
        history.push('/room-page')
      }
    }

    if (prevPath === '/room-page' && pinData.join(',') === pinFromStorage.join(',')) {
      history.push('/logout')
      // setFirstEnteredPin([])
      // dispatch(setPin(''))
      // dispatch(setPrevPath('/'))
    } else if (pinData.length === 4 && prevPath === '/room-page' && pinData.join(',') !== pinFromStorage.join(',')) {
      setError('Incorrect Pin Code');
      setPinData([])
    }
  }, [pinData]);

  function pinText () {
    if (prevPath === '/room-page') {
      return `Use PIN to unlock this device`
    } else {
      return `${firstEnteredPin.length === 4 ? 'Please confirm 4-digit PIN code' : 'Please enter 4-digit PIN code \n to setup device'}`
    }
  }

  const handlePinPadClick = (e) => {
    setPinData(Boolean(e.target.id) && pinData.length < 4 ? [...pinData, e.target.id] : pinData);
  }
  // console.log(firstEnteredPin, pinData)

  const handleBackSpaceClick = (e) => {
    e.stopPropagation();
    setError('');
    setPinData(pinData.length !== 0 ? pinData.slice(0, -1) : pinData);
  }
  return (
    <div className="pin-container">
      <div className="icons-row">
        {online ? <div></div> : <WifiOff/>}
        <HighlightOff onClick={() => {
          if (prevPath === '/room-page') {
            history.push('/room-page');
          } else {
            dispatch({type: 'AAD_LOGIN_SUCCESS', payload: ''});
            history.push('/acctype');
          }
        }}/>
      </div>
      <div className="pin-wrapper">
        <Uma fill={'#6633cc'} height={30}/>
        {/*<label>{firstEnteredPin.length === 4 ? 'Please confirm 4-digit PIN code' : 'Eenter 4-digit PIN code \n to setup device'}</label>*/}
        <label>{pinText()}</label>
        <div className="pin-input">
          <div className={`${pinData.length <= 0 ? 'small' : ''}`}></div>
          <div className={`${pinData.length <= 1 ? 'small' : ''}`}></div>
          <div className={`${pinData.length <= 2 ? 'small' : ''}`}></div>
          <div className={`${pinData.length <= 3 ? 'small' : ''}`}></div>
        </div>


        <div className="pin-pad" onClick={handlePinPadClick}>
          <button id="1">1</button>
          <button id="2">2</button>
          <button id="3">3</button>
          <button id="4">4</button>
          <button id="5">5</button>
          <button id="6">6</button>
          <button id="7">7</button>
          <button id="8">8</button>
          <button id="9">9</button>
          <div></div>
          <button id="0">0</button>
          <button onClick={handleBackSpaceClick} className="backspace">
            <Backspace></Backspace>
          </button>
        </div>
        <div className={`lic-error ${error ? '' : 'invisible'}`}>{error}</div>
        {/*<button disabled={pinData.length !== 4} className="submit-button"*/}
        {/*        onClick={enterPinHandler}>{firstEnteredPin.length === 4 ? 'CONFIRM PIN' : 'ENTER PIN'}</button>*/}
      </div>
    </div>
  )
};
