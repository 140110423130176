import React, {useEffect} from 'react';
import WifiOff from '@material-ui/icons/WifiOff';
import {signIn, signOut, getTokenRedirect} from '../authProvider';
import HighlightOff from '@material-ui/icons/HighlightOff';
import {Uma} from '../IconSet';
import {useHistory} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {useSelector, useDispatch} from 'react-redux';
import {setPrevPath} from '../redux/actions/dataActions';
import {Redirect} from 'react-router';
import store from '../redux';
import {AAD_LOGIN_SUCCESS} from '../constants/azureActions';
import Loader from './Loader';

export const AccountType = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [checkboxes, setCheckboxes] = React.useState([false, false]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [online, setOnline] = React.useState(navigator.onLine);
  const account = useSelector(state => state.azure.account);
  const prevPath = useSelector(state => state.data.prevPath);

  function isOnline () {
    window.addEventListener('online', (e) => setOnline(navigator.onLine))
    window.addEventListener('offline', (e) => setOnline(navigator.onLine))
  }

  useEffect(() => {
    isOnline()
  }, [online])

  useEffect(() => {
    const accountName = JSON.parse(localStorage.getItem('accountName'));
    if (account && prevPath !== 'redirectFromLicKey') {
      setIsLoading(false);
      if (accountName === 'mailbox') {
        history.push('/room-select')
      } else {
        history.push('/room-list');
      }
    }
  }, [account])

  const onChange = (e, changedIndex) => {
    const {checked} = e.target;
    localStorage.setItem('accountName', JSON.stringify(e.target.id));
    setCheckboxes(checkboxes.map((_, i) => i === changedIndex ? checked : false));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    signIn();
  }

  return (
    isLoading ? (<div className='auth-loader'><Loader color="#3e2e83"/></div> ): (
      <div className="account-type-container">
        <div className="icons-row">
          {online ? <div></div> : <WifiOff/>}
        </div>
        <form onSubmit={handleSubmit} className="account-type">
          <Uma fill={'#6633cc'} height={30}/>
          <label className="account-type__label"
                 htmlFor="licenseKey">{'Select account type you are using for logging into \n the meeting room'}</label>
          <div className="items-list">
            <label className="items-list__item" htmlFor="delegate">
              <p>Delegate Account</p>
              <div className="container">
                <div className="round">
                  <input type="checkbox"
                         id="delegate"
                         name="delegate"
                         checked={checkboxes[0]}
                         readOnly={true}
                         onClick={e => onChange(e, 0)}
                         />
                  <label htmlFor="delegate">
                  </label>
                </div>
              </div>
            </label>

            <label className="items-list__item" htmlFor="mailbox">
              <p>Mailbox Account</p>
              <div className="container">
                <div className="round">
                  <input type="checkbox"
                         id="mailbox"
                         checked={checkboxes[1]}
                         readOnly={true}
                         onClick={e => onChange(e, 1)}
                         />
                  <label htmlFor="mailbox">

                  </label>
                </div>
              </div>
            </label>
          </div>
          <button disabled={!checkboxes.some(el => el)} className="submit-button" type="submit">SIGN IN</button>
          <Button disableRipple style={{color: '#3e2e83', backgroundColor: 'transparent'}}
                  className="submit-button" onClick={() => {
                    dispatch(setPrevPath('/acctype'));
                    history.push('/')
          }}>ENTER LICENSE KEY</Button>
        </form>
      </div>
    )
  )
}
