import * as msal from '@azure/msal-browser';
import store from './redux';
import {AAD_LOGIN_ERROR} from './constants/azureActions';

const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_AZURE_CLIENT_ID}`,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: `https://${process.env.REACT_APP_REDIRECT_URL || 'localhost:3001'}/auth`,
    postLogoutRedirectUri: `https://${process.env.REACT_APP_REDIRECT_URL || 'localhost:3001'}/acctype`,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
  }
};

// Add here the scopes that you would like the user to consent during sign-in
const loginRequest = {
  scopes: ['User.Read']
};

// Add here the scopes to request when obtaining an access token for MS Graph API
const tokenRequest = {
  scopes: [
    'openid',
    'profile',
    'offline_access',
    'Mail.Read',
    'Calendars.ReadWrite.Shared',
    'Contacts.Read',
    'User.Read',
    'User.ReadBasic.All',
    'Calendars.ReadWrite',
    'Place.Read.All'
  ],
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};
//
// const msalInstance = new msal.PublicClientApplication(msalConfig);
//
// msalInstance.handleRedirectPromise().then((tokenResponse) => {
//   // Check if the tokenResponse is null
//   // If the tokenResponse !== null, then you are coming back from a successful authentication redirect.
//   // If the tokenResponse === null, you are not coming back from an auth redirect.
//   console.log('handleRedirectPromise:', tokenResponse);
// }).catch((error) => {
//   // handle error, either in the library or coming back from the server
//   console.error('handleRedirectPromise:', error);
// });
//
// try {
//   msalInstance.loginRedirect(loginRequest).then((succ)=>{
//     console.log('loginRedirect success', succ);
//   }, err=> console.error(err));
// } catch (err) {
//   // handle error
//   console.error('loginResponse error',err);
// }


// Create the main myMSALObj instance
// configuration parameters are located at authConfig.js
const myMSALObj = new msal.PublicClientApplication(msalConfig);

let accessToken;
let username = store.getState().azure.account;

// Redirect: once login is successful and redirects with tokens, call Graph API
myMSALObj.handleRedirectPromise().then(handleResponse).catch(err => {
  store.dispatch({
    type: 'AAD_LOGIN_ERROR',
    payload: err.errorCode
  })
});

function handleResponse (resp) {
  store.dispatch({
    type: 'AAD_AUTHENTICATED_STATE_CHANGED',
    payload: ''
  })
  if (resp !== null) {
    username = resp.account.username;
  } else {
    /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    const currentAccounts = myMSALObj.getAllAccounts();
    if (currentAccounts === null) {
      return;
    } else if (currentAccounts.length > 1) {
      // Add choose account code here
      // console.warn('Multiple accounts detected.');
    } else if (currentAccounts.length === 1) {
      username = currentAccounts[0].username;
      // console.log('username in handleResponse ', username);
    }
  }
  if (username) {
    store.dispatch({
      type: 'AAD_LOGIN_SUCCESS',
      payload: username
    })
    store.dispatch({
      type: 'AAD_AUTHENTICATED_STATE_CHANGED',
      payload: 'Authenticated'
    })
  }
}

export function signIn () {
  loginRequest.prompt = 'select_account';
  return myMSALObj.loginRedirect(loginRequest);
}

export function signOut () {
  const logoutRequest = {
    account: myMSALObj.getAccountByUsername(username)
  };
  myMSALObj.logout(logoutRequest).then(() => {
    store.dispatch({
      type: 'AAD_LOGOUT_SUCCESS'
    })
  })
}

export function getTokenRedirect (request = tokenRequest) {
  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  request.account = myMSALObj.getAccountByUsername(username);
  // console.log(request, username);
  return myMSALObj.acquireTokenSilent(request).catch(error => {
    console.warn('silent token acquisition fails. acquiring token using redirect');
    if (error instanceof msal.InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      return myMSALObj.acquireTokenRedirect(request);
    } else {
      console.warn('else', error);
    }
  });
}
