import {SET_ROOM_NAME, SET_LICENCE_KEY, SET_CALENDAR_PARAM, SET_PIN, SET_CONFIRM_PIN, SET_PREV_PATH, SET_EXP_DATE} from '../../constants/dataActions';

const initialState = {
  roomName: '',
  licenceKey: '',
  expDate: '',
  calendarParam: {
    selectedRoomEmailAddress: '',
    checkedId: false
  },
  pin: '',
  confirmPin: '',
  prevPath:''

};

export const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROOM_NAME:
      return {
        ...state,
        roomName: action.payload
      };
    case SET_LICENCE_KEY:
      return {
        ...state,
        licenceKey: action.payload
      };
    case SET_EXP_DATE:
      return {
        ...state,
        expDate: action.payload
      };
    case SET_CALENDAR_PARAM:
      return {
        ...state,
        calendarParam: action.payload
      };
    case SET_PIN:
      return {
        ...state,
        pin: action.payload
      };
    case SET_CONFIRM_PIN:
      return {
        ...state,
        confirmPin: action.payload
      };
    case SET_PREV_PATH:
      return {
        ...state,
        prevPath: action.payload
      }
    default:
      return state;
  }
};
