export const AAD_INITIALIZING = 'AAD_INITIALIZING';
export const AAD_INITIALIZED = 'AAD_INITIALIZED';
export const AAD_LOGIN_SUCCESS = 'AAD_LOGIN_SUCCESS';
export const AAD_LOGIN_FAILED = 'AAD_LOGIN_FAILED';
export const AAD_LOGIN_ERROR = 'AAD_LOGIN_ERROR';
export const AAD_CLEAR_ERROR = 'AAD_CLEAR_ERROR';
export const AAD_LOGOUT_SUCCESS = 'AAD_LOGOUT_SUCCESS';
export const AAD_ACQUIRED_ID_TOKEN_SUCCESS = 'AAD_ACQUIRED_ID_TOKEN_SUCCESS';
export const AAD_ACQUIRED_ID_TOKEN_ERROR = 'AAD_ACQUIRED_ID_TOKEN_ERROR';
export const AAD_ACQUIRED_ACCESS_TOKEN_SUCCESS = 'AAD_ACQUIRED_ACCESS_TOKEN_SUCCESS';
export const AAD_ACQUIRED_ACCESS_TOKEN_ERROR = 'AAD_ACQUIRED_ACCESS_TOKEN_ERROR';
export const AAD_AUTHENTICATED_STATE_CHANGED = 'AAD_AUTHENTICATED_STATE_CHANGED';
export const Unauthenticated = 'Unauthenticated';
export const InProgress = 'InProgress';
export const Authenticated = 'Authenticated';