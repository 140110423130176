import {useHistory} from 'react-router-dom';
import {useFormik} from 'formik';
import WifiOff from '@material-ui/icons/WifiOff';
import HighlightOff from '@material-ui/icons/HighlightOff';
import {Uma} from '../IconSet';
import MaskedInput from 'react-text-mask';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setExpDate, setLicenceKey, setPrevPath} from '../redux/actions/dataActions';
import isValidLicence from '../shared/Utils';
// import md5 from 'crypto-js/md5';
// import moment from 'moment';

export const Licence = () => {
  const dispatch = useDispatch();
  const licenceKey = useSelector(state => state.data.licenceKey);
  const expDate = useSelector(state => state.data.expDate);
  let history = useHistory();
  const [online, setOnline] = React.useState(navigator.onLine);
  const prevPath = useSelector(state => state.data.prevPath);

  function isOnline() {
    window.addEventListener('online', (e) => setOnline(navigator.onLine))
    window.addEventListener('offline', (e) => setOnline(navigator.onLine))
  }

  useEffect(() => {
    isOnline()
  }, [online]);

  const validate = values => {
    const errors = {};
    if (!values.licenseKey) {
      dispatch(setExpDate(''))
      errors.licenseKey = 'Required';
    } else if (!isValidLicence(values.licenseKey)) {
      errors.licenseKey = 'INCORRECT KEY';
    }
    return errors;
  }

  const formik = useFormik({
    initialValues: {
      licenseKey: licenceKey,
    },
    validateOnChange: false,
    validate,
    onSubmit: values => {
      dispatch(setLicenceKey(values.licenseKey));
      dispatch({type: 'AAD_LOGIN_SUCCESS', payload: ''});
      prevPath === '/room-page' ? history.push('/logout') : history.push('/acctype')
    },
  });

  useEffect(() => {
    if (licenceKey && isValidLicence(licenceKey) && prevPath === null) {
      dispatch({type: 'AAD_LOGIN_SUCCESS', payload: ''});
      dispatch(setPrevPath('redirectFromLicKey'));
      history.push('/acctype');
    }
  }, [])


  return (
      <div className="license-form-container">
        <div className="icons-row">
          {online ? <div></div> : <WifiOff/>}
          {prevPath === '/room-page' ? <HighlightOff onClick={() => {
            prevPath === '/room-page' ? history.push('/logout') : history.push('/acctype')
          }
          }/> : <div></div>}
        </div>
        <form onSubmit={formik.handleSubmit} className="license-form">
          <Uma fill={'#6633cc'} height={30}/>
          <label htmlFor="licenseKey">Please enter license key</label>
          {/*<pre style={{*/}
          {/*  background: '#f6f8fa',*/}
          {/*  fontSize: '.65rem',*/}
          {/*  padding: '.5rem',*/}
          {/*  textAlign: 'left'*/}
          {/*}}>{JSON.stringify(formik, null, 2)}</pre>*/}
          {/*<pre>{formik.errors.licenseKey}</pre>*/}
          <MaskedInput
              mask={[/^[a-zA-Z0-9]+$/, /^[a-zA-Z0-9]+$/, /^[a-zA-Z0-9]+$/, '-',
                /^[a-zA-Z0-9]+$/, /^[a-zA-Z0-9]+$/, /^[a-zA-Z0-9]+$/, '-',
                /^[a-zA-Z0-9]+$/, /^[a-zA-Z0-9]+$/, /^[a-zA-Z0-9]+$/, '-',
                /^[a-zA-Z0-9]+$/, /^[a-zA-Z0-9]+$/, /^[a-zA-Z0-9]+$/]}
              id="licenseKey"
              name="licenseKey"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.licenseKey}
              placeholder="XXX-XXX-XXX-XXX"
              className={`${formik.isValid ? '' : 'error'}`}
          />
          {
            expDate ?
                <div className='lic-error'>{'Expires ' + expDate}</div> :
                <div className={`lic-error ${!formik.isValid ? '' : 'invisible'}`}>{formik.errors?.licenseKey}</div>
          }
          <button className="submit-button"
                  type="submit">Submit
          </button>
        </form>
      </div>
  )
};
