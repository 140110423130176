import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useDispatch} from "react-redux";
import {setRoomName} from "../redux/actions/dataActions";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
  dialogTitle: {
    paddingBottom: 0
  },
  bottomBtn: {
    color: 'black',
    fontWeight: 'bold'
  },
  nameModalTitle: {
    fontSize: '1.5rem',
    color: 'black'
  },
  resize: {
    fontSize: '1.5rem'
  },
  MuiPaperRoot: {
    minHeight: '214px',
  }
})
export default function RoomNameModal(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {open, handleClose, handleSubmitRoomName, roomName} = props;
  const [roomChangedName, setRoomChangedName] = React.useState(roomName);

  function roomNameHandler() {
    if (roomChangedName) {
      dispatch(setRoomName(roomChangedName))
      localStorage.setItem('roomName', JSON.stringify(roomChangedName))
      return handleSubmitRoomName(roomChangedName)
    } else if (roomName) {
      dispatch(setRoomName(roomName))
      localStorage.setItem('roomName', JSON.stringify(roomName))
      return handleSubmitRoomName(roomName)
    }
  }

  return (
      <div>
        <Dialog open={open} onClose={handleClose} maxWidth="sm"
                fullWidth={true} aria-labelledby="form-dialog-title" classes={{paper: classes.MuiPaperRoot}}>
          {/*<DialogTitle id="form-dialog-title">ENTER CALENDAR NAME</DialogTitle>*/}
          <DialogTitle className={classes.dialogTitle}>
            Enter calendar name
          </DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.nameModalTitle}>
              This name will be displayed as room name
            </DialogContentText>
            <TextField
                InputProps={{
                  classes: {
                    input: classes.resize
                  }
                }}
                // className={classes.resize}
                size="medium"
                autoFocus
                defaultValue={roomName}
                margin="dense"
                id="name"
                // label="ROOM NAME"
                onChange={(e) => setRoomChangedName(e.target.value)}
                fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} className={classes.bottomBtn}>CANCEL
            </Button>
            <Button
                onClick={() => {
                  roomNameHandler()
                }} className={classes.bottomBtn}>SUBMIT
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}
