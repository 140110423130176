import React from 'react';
import './App.scss';

import {Route} from 'react-router';
import {AccountType} from './components/AccountType';
import {LogOut} from './components/LogOut';
import {Licence} from './components/Licence';
import {Pin} from './components/Pin';
import {RoomList} from './components/RoomList';
import {RoomSelect} from './components/RoomSelect';
import {Auth} from './components/Auth';
import {Provider} from 'react-redux';
import RoomPage from './components/RoomPage';
import store from './redux'
import RouteGuard from './components/RouteGuard';

function App () {
  return (
    <Provider store={store}>
      <div className="App">
        <Route exact={true} path="/" component={Licence}/>
        <RouteGuard path="/acctype" component={AccountType}/>
        <RouteGuard path="/room-list" component={RoomList}/>
        <RouteGuard path="/room-select" component={RoomSelect}/>
        <RouteGuard path="/pin" component={Pin}/>
        <RouteGuard path="/room-page" component={RoomPage}/>
        <RouteGuard path="/logout" component={LogOut}/>
        <RouteGuard path="/auth" component={Auth}/>
      </div>
    </Provider>
  );
}

export default App;