import {setExpDate} from '../redux/actions/dataActions';
import moment from 'moment';
import md5 from 'crypto-js/md5';
import store from '../redux';

const SecretKey = '9bfbf7f3-9d19-41c5-a637-ae6dfdffeee3'
const UTFAlphabetStart = 65;
const AlphabetCapacity = 25;

const isValidLicence = (licence) => {
  licence = licence.toUpperCase();
  if (checkLicenceHash(licence)) {
    const date = getLicenceExpirationDate(licence);
    const isDateValid = checkDate(date);
    isDateValid && store.dispatch(setExpDate(date));
    if(moment().format() > date){
      store.dispatch(setExpDate(''));
    }
    return isDateValid;
  }
  store.dispatch(setExpDate(''));
  return false;
};

const getLicenceExpirationDate = (licence) => {
  const groups = licence.toUpperCase().split('-');
  const y = decodeValue(groups[0], Math.floor(UTFAlphabetStart + AlphabetCapacity / 2));
  const m = decodeValue(groups[1], UTFAlphabetStart + AlphabetCapacity);
  const d = decodeValue(groups[2], UTFAlphabetStart + AlphabetCapacity);

  return `202${y}-${('00' + m).slice(-2)}-${('00' + d).slice(-2)}`;
};

const checkDate = (date) => {
  const now = moment();
  const licExp = moment(date);
  return now.isBefore(licExp);
};

const decodeValue = (value, offset) => {
  const d = value.slice(-1);
  if (Number.isInteger(Number.parseInt(d, 10))) {
    return Number.parseInt(d) + AlphabetCapacity;
  } else {
    return offset - value.charCodeAt(value.length - 1);
  }
};

const checkLicenceHash = (licence) => {
  const dividerIndex = licence.lastIndexOf('-');
  const dataPart = licence.substring(0, dividerIndex);
  const hashPart = licence.substring(dividerIndex + 1);

  const hashDigest = md5(dataPart + SecretKey).toString();
  return (hashDigest.toLowerCase().endsWith(hashPart.toLowerCase()));
};

export default isValidLicence;