import {
  SET_ROOM_NAME,
  SET_LICENCE_KEY,
  SET_CALENDAR_PARAM,
  SET_PIN,
  SET_PREV_PATH,
  SET_EXP_DATE
} from '../../constants/dataActions';

export const setRoomName = (name) => {
  return dispatch => {
    dispatch({type: SET_ROOM_NAME, payload: name});
  };
};

export const setLicenceKey = (key) => {
  return dispatch => {
    dispatch({type: SET_LICENCE_KEY, payload: key});
  };
};

export const setExpDate = (date) => {
  return dispatch => {
    dispatch({type: SET_EXP_DATE, payload: date});
  };
};

export const setCalendarParam = (data) => {
  return dispatch => {
    dispatch({type: SET_CALENDAR_PARAM, payload: data})
  }
}
export const setPin = (pin) => {
  return dispatch => {
    dispatch({type: SET_PIN, payload: pin})
  }
}

export const setPrevPath = (path) => {
  return dispatch => {
    dispatch({type: SET_PREV_PATH, payload: path})
  }
}