import React, {useEffect} from 'react';
import {Redirect} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import Loader from "./Loader";
import {useHistory} from 'react-router-dom';

export const Auth = () => {
  const account = useSelector(state => state.azure.account);
  const state = useSelector(state => state.azure.state);
  const accountName = JSON.parse(localStorage.getItem('accountName'));
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if(account){
      accountName === 'mailbox' ? history.push('/room-select') : history.push('/room-list');
      // accountName === 'mailbox' ? console.log('/room-select') : console.log('/room-list');
    }
  }, [account]);

  useEffect(() => {
    if (state === 'access_denied') {
      dispatch({type: 'AAD_LOGIN_SUCCESS', payload: ''});
      history.push('/acctype');
    }

    // state === 'access_denied' && console.log('/acctype');
    // {
    //   pathname: '/acctype',
    //     search: '?query=fromAuth',
    //   state: { detail: "bla bla" }
    // }
  }, [state])

  return <div className='auth-loader'><Loader color="#3e2e83"/></div>;
}