import React from 'react';

export const Uma = (props) => {
  const {fill, viewBox, height, width} = props;
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      preserveAspectRatio='xMidYMid meet'
      viewBox={viewBox || '0 0 643 203'}
      width={width || '643'}
      height={height || '203'}
      fill={fill || 'white'}>
      <defs>
        <path
          d='M6.18 63.25L6.29 126.53C6.78 129.21 7.05 130.69 7.11 130.99C10.75 151.16 17.56 165.68 28.95 177.5C41.76 190.85 59.72 199.06 80.63 201.13C80.78 201.14 81.52 201.21 82.85 201.34L82.85 183.21L82.85 165.04C80.98 164.44 79.95 164.11 79.74 164.04C62.89 158.58 50.62 144.98 46.26 126.96C46.2 126.71 45.9 125.5 45.37 123.32L45.37 61.64L45.37 0L25.74 0L6.11 0L6.18 63.25Z'
          id='b5emMDAsKy'>
        </path>
        <path
          d="M139.88 62.53L139.77 125.1C139.28 126.81 139.01 127.77 138.95 127.96C133.67 146.16 122 158.79 105.62 164.04C105.41 164.11 104.36 164.46 102.48 165.08L102.48 183.21L102.48 201.34C103.83 201.21 104.57 201.14 104.72 201.13C125.89 199.06 143.27 191.1 156.59 177.32C167.72 165.83 174.25 151.91 178.21 131.35C178.27 131.07 178.53 129.71 179 127.24L179.11 63.6L179.21 0L159.58 0L139.95 0L139.88 62.53Z"
          id="e16sQ5jDp"></path>
        <path
          d="M221.4 7.57L221.51 12.67C250.53 50.62 266.65 71.7 269.87 75.92C296.46 110.68 318.31 139.16 318.41 139.16C318.52 139.16 331.05 124.17 346.25 105.86C361.46 87.52 374.02 72.49 374.2 72.49C374.34 72.46 374.45 101.44 374.45 136.88C374.45 141.17 374.45 162.65 374.45 201.3L394.62 201.3L414.78 201.3L414.78 101.9L414.78 2.5L395.76 2.5L376.73 2.5L347.47 40.51L318.24 78.56C317.7 77.92 317.4 77.56 317.34 77.49C316.84 76.92 303.67 59.82 288.08 39.48C286.18 37.01 276.73 24.7 259.7 2.53L240.5 2.53L221.3 2.5L221.4 7.57Z"
          id="bdjTJvyWj"></path>
        <path
          d="M515.68 7.39C514.58 10.1 513.65 12.53 513.65 12.74C513.65 13.56 534.49 61.32 545.95 86.73C552.2 100.58 557.37 112.11 557.44 112.32C557.59 112.68 548.7 112.79 514.33 112.86C511.44 112.87 497.01 112.9 471.03 112.97C466.9 122.71 464.6 128.12 464.14 129.21C460.36 138.13 457.26 145.55 457.26 145.7C457.26 145.87 483.24 146.02 514.97 146.05C518.82 146.06 538.07 146.1 572.72 146.16L585.11 173.64L597.49 201.13C610.23 201.19 617.31 201.23 618.73 201.23C635.65 201.3 640 201.23 640 200.88C640 200.63 620.69 155.9 597.06 101.54C594.2 94.95 579.89 62 554.12 2.68L535.96 2.57L517.75 2.5C516.65 5.11 515.96 6.74 515.68 7.39Z"
          id="a2ZYjOkvzd"></path>
        <path
          d="M221.4 166.4L221.51 201.13L240.53 201.23L259.52 201.3L259.52 166.5L259.52 131.7L240.43 131.7L221.33 131.7L221.4 166.4Z"
          id="d2zz7NdmjW"></path>
        <path
          d="M444.51 181.6C440.55 191.77 437.2 200.34 437.05 200.7C436.84 201.27 438.05 201.3 456.44 201.3C457.74 201.3 464.29 201.3 476.07 201.3C478.98 194.39 480.6 190.54 480.92 189.78C483.6 183.46 487.2 174.86 488.95 170.68C489.16 170.18 490.24 167.65 492.16 163.11L471.96 163.11L451.72 163.11C447.88 172.97 445.48 179.14 444.51 181.6Z"
          id="a2gyC791bs"></path>
      </defs>
      <g>
        <g>
          <g>
            <use xlinkHref="#b5emMDAsKy" opacity="1" ></use>
            <g>
              <use xlinkHref="#b5emMDAsKy" opacity="1"  stroke="#000000" ></use>
            </g>
          </g>
          <g>
            <use xlinkHref="#e16sQ5jDp" opacity="1" ></use>
            <g>
              <use xlinkHref="#e16sQ5jDp" opacity="1"  stroke="#000000" ></use>
            </g>
          </g>
          <g>
            <use xlinkHref="#bdjTJvyWj" opacity="1" ></use>
            <g>
              <use xlinkHref="#bdjTJvyWj" opacity="1"  ></use>
            </g>
          </g>
          <g>
            <use xlinkHref="#a2ZYjOkvzd" opacity="1" ></use>
            <g>
              <use xlinkHref="#a2ZYjOkvzd" opacity="1"  stroke="#000000" ></use>
            </g>
          </g>
          <g>
            <use xlinkHref="#d2zz7NdmjW" opacity="1" ></use>
            <g>
              <use xlinkHref="#d2zz7NdmjW" opacity="1"  stroke="#000000" ></use>
            </g>
          </g>
          <g>
            <use xlinkHref="#a2gyC791bs" opacity="1" ></use>
            <g>
              <use xlinkHref="#a2gyC791bs" opacity="1"  ></use>
            </g>
          </g>
        </g>
        {/*<animate attributeName="fill" values="red;blue;red" dur="10s" repeatCount="indefinite" />*/}
      </g>
    </svg>
  )
}
