import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    const serialized = JSON.parse(serializedState);
    serialized.data.prevPath = null;
    return serialized;
  } catch (err) {
    return undefined;
  }
};

let persistedState = loadState();

export default function configureStore() {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  return createStore(
    rootReducer,
    persistedState,
    composeEnhancer(applyMiddleware(thunk)),
  );
}
