import React, {useEffect} from 'react';
import WifiOff from '@material-ui/icons/WifiOff';
import HighlightOff from '@material-ui/icons/HighlightOff';
import {Uma} from '../IconSet';
import {useHistory} from 'react-router-dom';
import Button from "@material-ui/core/Button";
import {useDispatch} from "react-redux";
import {setPin, setPrevPath} from "../redux/actions/dataActions";

export const LogOut = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    const [online, setOnline] = React.useState(navigator.onLine);

    function isOnline() {
        window.addEventListener('online', (e) => setOnline(navigator.onLine))
        window.addEventListener('offline', (e) => setOnline(navigator.onLine))
    }

    useEffect(() => {
        isOnline()
    }, [online])

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(setPin(''));
        dispatch(setPrevPath('/'));
        dispatch({type: 'AAD_LOGIN_SUCCESS', payload: ''});
        localStorage.removeItem('prevPath');
        history.push('/acctype');
    }

    return (
        <div className="logout-container">
            <div className="icons-row">
                {online ? <div></div> : <WifiOff/>}
                <HighlightOff onClick={()=> history.push('/room-page')} />
            </div>
            <form onSubmit={handleSubmit} className="account-type">
                <Uma fill={'#6633cc'} height={38}/>
                <button className="submit-button" type="submit">LOG OUT</button>
                <Button disableRipple style={{color: '#3e2e83', backgroundColor: 'transparent', border: 'none'}}
                        className="submit-button" onClick={() => {
                    history.push('/')
                }}>ENTER LICENSE KEY</Button>
            </form>

        </div>
    );
}
